.ul-percent {
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
}
.ul-percent p {
  margin-right: 10px;
  margin-bottom: 10px;
}
.ul-percent p label {
  border: 1px solid grey;
  padding: 4px 12px;
  border-radius: 20px;
}
.ul-percent p .selected {
  border: 3px solid #56b96e !important;
}
.ul-percent p label input {
  margin-right: 10px;
}
.ncbmsg {
  background: #fff4e7;
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid #ffe1bd;
  color: #f39930;
  font-size: 12px;
  letter-spacing: 0.1 px;
  position: relative;
}
.v2_btngrp .button {
  font-size: 18px;
  height: 50px;
  box-shadow: 0 8px 8px rgba(121, 177, 124, 0.2);
  border-radius: 8px;
  background-color: #56b96e;
  color: white;
  border: none;
  width: 100% !important;
}
.v2_btngrp .cancel {
  box-shadow: none;
  font-weight: 600;
  background-color: white;
  color: green;
}

.plan-type-list {
  border: none;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.09);
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.plan-type-list-active {
  border: 3px solid #56b96e;
}
.plan-type-list-span {
  font-weight: 600;
}
.plan-type-list-span-inner {
  font-size: 14px;
}
.plan-type-list-span-recommended {
  background-color: #56b96e;
  color: white;
  border-radius: 5px;
  padding: 3px 7px;
  font-size: 12px;
  margin-left: 10px;
}

.filterseries {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
}
.filterseries label input {
  margin-right: 10px;
}

.idv-choose-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondaryButton {
  font-size: 15px;
  height: 38px;
  /* box-shadow: 0 8px 8px rgba(121, 177, 124, 0.2); */
  border-radius: 8px;
  background-color: #56b96d;
  color: white;
  border: none;
  width: 30%;
}

.filterbrand-input {
  width: 100%;
  box-shadow: none;
  border: none;
  -webkit-text-fill-color: #333846;
  display: inline-block;
  background-color: #f5f5f6;
  padding: 0 10px;
}
.filterbrand-outer {
  background-color: #f5f5f6;
  padding: 8px 15px;
  border-radius: 6px;
  /* margin-right: 10px; */
  display: flex;
  gap: 10px;
}
.add-ons-inner {
  margin-bottom: 8px;
  margin-right: 10px;
}
.add-ons-inner label input {
  margin-right: 10px;
}
.add-ons-outer {
  /* height: 250px; */
  overflow-y: auto;
}
.v2_btngrp {
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
}
.v2_btngrp-filter {
  justify-content: flex-end !important;
}
.v2_btngrp .wrapper {
  width: 100%;
}
.appliedno .match {
  font-weight: 400;
  font-size: 14px;
}
.container-btn {
  background-color: #fff;
  width: 100%;
  /* padding: 20px 10px; */
  /* border-radius: 0.625rem; */
  /* border-top-left-radius: 0.625rem; */
  /* border-top-right-radius: 0.625rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.container-btn button {
  min-width: 100%;
  /* height: 50px; */
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #354c89;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.container-btn button.active {
  align-items: flex-start;
  justify-content: inherit;
}
.container-btn button.active svg {
  margin-top: 4px;
}

.container-btn2 {
  background-color: #fff;
  position: relative;
  width: 95%;
  padding: 20px 10px;
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: baseline;
  align-items: center;
}
.container-btn2 p {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 10px !important;
}
.container-btn2 p {
  min-width: 8%;
}
.container-btn2 button {
  height: 50px;
  /* min-width: 25%; */
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #354c89;
  display: flex;
  margin-right: 10px;
  align-items: center;
}
.container-btn2 .active {
  border: 2px solid #56b96c;
  color: #56b96c;
  font-weight: 500;
}

/* .container-btn2 button:last-child{
  position: absolute;
  right: 0;
  width: 140px !important;
  z-index: 9999;
  border-radius: 30px;
} */
/* new */

.main-quote-list {
  background: #f5f5f7;
  padding: 30px 3.2rem;
  /* margin-top: 0.8rem; */
  display: flex;
}

.quote-list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
}

.list-quotes {
  width: 100%;
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
  scrollbar-width: none;
}

.customer-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customer-deatils {
  margin-top: 0;
  width: 100%;
  border-radius: 10px;
  padding: 0 1rem;
  background-color: #fff;
  /* height: 203px; */
}
.customer-deatils p {
  color: #4f4f4f;
  /* font-family: Mulish; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.customer-deatils-see-story {
  color: #56b96c !important;
  font-weight: 700 !important;
  line-height: 1.25rem !important;
  font-size: 18px !important;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.customer-deatils button a {
  text-decoration: none;
  color: #56b96c;
}
.my-deatils {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
/* .quote-text {
  margin-top: 1rem;
} */
.quote-card-1 {
  padding: 10px 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* gap: 10px 15px; */
  border-radius: 15px;
  flex-wrap: wrap;
  background: #fff;
  /* margin-bottom: 1rem; */
}
.quote-img-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.quote-card-1-span {
  position: absolute;
  top: 0;
  left: 4%;
  background-color: red;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.quote-img {
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  width: 33%;
}
.quote-img img {
  width: 100%;
  max-width: 125px;
  object-fit: contain;
  /* height: 80px; */
  border: none;
  border-radius: 10px;
}
.digit_img img {
  width: 100%;
  max-width: 90px;
  object-fit: contain;
  /* height: 80px; */
  border: none;
  border-radius: 10px;
}

.vr-line {
  width: 0.04rem;
  height: 4rem;
  background-color: #5d5d5d;
}
.quote-heading {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.quote-heading h3 {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 200;
  line-height: 28px;
  text-transform: capitalize;
}
.quote-heading p {
  color: #000;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
}
.care-hospital h6 {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px; /* 200% */
}
.adds-card {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.adds-card img {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}
.container-btn-second {
  padding: 10px 16px !important;
  display: flex;
  overflow: hidden !important;
  border-radius: 8px;
  margin-bottom: 10px;
  justify-content: flex-start !important;
}

.quote-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 33%;
}
.quote-btn p {
  /* align-self: flex-end; */
  font-weight: 600;
}
.quote-btn p span {
  text-align: end;
  padding-left: 10px;
}
.quote-btn button {
  width: auto;
  padding: 8px 15px;
  margin: 0;
  border: none;
  color: #fff;
  border-radius: 5px;
  background: #56b96c;
  font-size: 15px;
  font-weight: bold;
}
.health_quote .quote-btn button {
  width: max-content;
  padding: 8px;
  font-size: 12px;
}
.care-text {
  font-weight: 600;
  margin: 7px;
}
.view-feature {
  color: #56b96c;
  font-weight: 600;
  margin-bottom: 10px;
}
.all-filter-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.all-filter-left {
  width: 30%;
}
/* .all-filter-inner {
} */
.all-filter-inner div {
  padding: 21px 16px;
  width: 100%;
  background: #f4f5f7;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #172b4d;
  position: relative;
  cursor: pointer;
}
.all-filter-inner .active {
  background: #fff;
  cursor: inherit;
  color: #36b37e;
  font-weight: 700;
  border-left: 4px solid #36b37e;
}
.all-filter-right {
  width: 70%;
  padding: 10px 30px;
}
.all-filter-right-inner-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.all-filter-right-inner-wrapper div {
  width: 48%;
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 10px;
}
.all-filter-right-inner-wrapper .active {
  border: 2px solid #36b37e;
  color: #36b37e;
  font-weight: 500;
}

/* health-quote */
.health_quote .quote-img {
  display: flex;
  /* align-items: center; */
  /* margin-right: 10px; */
  /* width: 70px; */
  /* height: 70px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
  /* flex: 0 0 70px; */
  overflow: hidden;
  /* justify-content: center; */
}
.health_quote .quote-img img {
  max-width: 100%;
  object-fit: cover;
  border: none;
}
.health_quote .quote-btn {
  width: max-content;
}
.health_quote .care-hospital h3 {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  width: auto;
}
.health_quote .care-hospital p {
  font-size: 12px;
  text-align: center;
}
@media (min-width: 280px) and (max-width: 1399px) {
  .did-floating-label-content {
    font-size: 12px;
    padding-bottom: 5px;
  }
  .last-Setp-left .did-floating-label-content {
    padding-bottom: 20px !important;
  }
  /* .did-floating-input::placeholder {
    font-size: 14px;
    padding: 20px;
  } */
}
@media (min-width: 992px) and (max-width: 1399px) {
  .container-btn button {
    font-size: 12px;
    padding: 10px;
  }
  .customer-deatils-see-story {
    font-size: 15px !important;
  }
  .my-name h5 b {
    font-size: 15px;
  }
  .customer-deatils p {
    font-size: 13px;
  }
  .my-name h5 {
    font-size: 15px;
    margin-bottom: 0;
  }
  .customer-deatils {
    padding: 0 10px;
  }
  .my-deatils {
    margin-top: 0;
  }
  .quote-btn button {
    font-size: 12px;
    padding: 10px;
    width: max-content;
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
  }
  .quote-left {
    gap: 20px;
  }
  .care-amount h6 {
    font-size: 14px;
  }
  .product_text {
    font-size: 12px;
  }
  .quote-btn {
    width: 33%;
    align-items: flex-end;
  }
  .care-amount {
    width: 33%;
    text-align: center;
  }
  .quote-img {
    margin-right: 0;
  }
  .quote-img img {
    max-width: 120px;
  }
  .invoice_content {
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .invoce_des {
    font-size: 10px;
  }
  .line {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .sec_text {
    font-size: 10px;
  }
  .edit {
    font-size: 13px;
  }
  .sec_heading {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .invoice_sec {
    padding: 12px;
  }
  .list-quotes {
    width: 100%;
  }
  .customer-review {
    width: 100%;
  }
  .main-quote-list {
    padding: 30px 14px;
  }
  .container-btn {
    justify-content: space-around !important;
  }
  .container-btn button:last-child {
    margin-top: 10px !important;
  }
  .adds-card {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .quote-right {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }
  .quote-left {
    width: 100%;
  }
  .main-care-container {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .product_text {
    font-size: 14px;
  }
  .main-care-container {
    margin-top: 0rem;
  }
  .quote-text p {
    margin-bottom: 10px;
    font-size: 15px;
  }
  .list-quotes {
    width: 100%;
  }
  .customer-review {
    width: 100%;
  }
  .quote-list {
    flex-direction: column;
  }
  .main-quote-list {
    padding: 10px;
    display: block;
  }
  .addon .accordion-item {
    margin-top: 10px;
    border: none;
  }
  .addon .accordion-body {
    padding: 10px 0px 0px;
  }
  /* .product_box{
  line-height: 16px;
} */
  .invoice_sec {
    margin-bottom: 7px;
    border-radius: 0;
  }
  .container-btn p {
    margin-bottom: 0;
  }
  .container-btn {
    width: 100%;
    overflow-x: scroll !important;
    overflow-y: hidden;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    padding-bottom: 10px;
    gap: 4px;
  }
  .line {
    border: 1px solid rgba(37, 56, 88, 0.12);
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .container-btn button {
    min-width: 170px;
    height: 40px;
    /* margin-right: 6px; */
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px;
    width: 100%;
  }
  .container-btn button:last-child {
    margin-top: 0 !important;
  }
  .adds-card {
    margin-top: 0 !important;
  }
  .quote-card-1-span {
    left: 5%;
    padding: 5px 15px;
    font-size: 12px;
  }
  .quote-card {
    margin-bottom: 15px;
  }
  .quote-card-1 {
    width: 100%;
  }
  /* .health_quote .care-hospital {
    width: 20%;
  } */
  .health_quote .care-hospital h3 {
    width: auto;
    font-size: 12px;
  }
  .health_quote .care-hospital h6 {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }
  .health_quote .quote-img {
    margin-right: 10px;
  }
  .quote-img {
    width: 33%;
    margin-right: 0;
  }
  .quote-img-outer {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    margin-top: 30px;
  }
  .vr-line {
    display: none;
  }
  .quote-heading h3 {
    font-size: 1.3rem;
    line-height: 16px;
  }
  .quote-heading p {
    color: #000;
    font-size: 0.7rem;
    font-weight: 400;
  }
  .care-amount {
    width: 33%;
    text-align: center;
  }
  .care-hospital h6 {
    font-size: 0.9rem;
    /* line-height: 10px; */
  }

  .quote-btn {
    width: 33%;
    align-items: flex-end;
  }
  .quote-btn p {
    font-size: 14px;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 5px !important;
  }
  .quote-btn p span {
    text-align: end;
    padding-left: 10px;
  }
  .quote-btn button {
    width: 70%;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
    /* margin-bottom: 4px; */
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
  }
  .health_quote .quote-btn button {
    width: 100%;
  }
  .health_quote .quote-btn button {
    font-size: 12px;
  }
  .care-text {
    margin: 5px;
  }
  .view-feature {
    margin-bottom: 5px;
  }
  .container-btn2 {
    width: 100%;
  }
  .container-btn2 p {
    display: none !important;
  }
  .container-btn2 button {
    height: 40px;
    margin-right: 6px;
    font-size: 14px;
  }
  .add-ons-inner {
    font-size: 13px;
    margin-bottom: 4px;
    margin-right: 7px;
  }
  .container-btn-second {
    padding: 5px 15px !important;
  }
}
@media (max-width: 767px) {
  .quote-right {
    width: 30%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  .quote-left {
    display: flex;
    width: 67%;
    justify-content: space-between;
  }
  .health_quote .quote-img {
    width: 150px;
  }
  .my-name {
    margin-top: 0;
  }
  .my-name h5 b {
    font-size: 18px;
  }
  .customer-deatils-see-story {
    font-size: 15px !important;
  }
  .my-name h5 {
    font-size: 15px;
    margin-bottom: 0;
  }
  .customer-deatils .my-name p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .customer-deatils p {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 12px;
  }

  /* .addon .accordion-item{
    margin-top: 0;
  } */

  .care-amount h6 {
    margin-bottom: 0;
    line-height: normal;
  }
  .product_text {
    font-size: 13px;
  }
  .container-btn button {
    min-width: auto;
    width: 100%;
  }
  .container-btn {
    justify-content: unset !important;
  }
}
@media (max-width: 575px) {
  .health_quote .quote-card-1 {
    gap: 10px 0;
  }
  .health_quote .care-hospital {
    width: 25%;
  }
  .health_quote .quote-right .care-hospital {
    width: 100%;
  }
  .health_quote .quote-btn {
    align-items: flex-end;
    gap: 5px;
  }
  .customer-deatils {
    padding: 10px;
  }
  .author-customer {
    margin-top: 0;
  }
  .addon .accordion-body {
    padding: 5px 0px 0px;
  }
  .product_text {
    font-size: 10px;
  }
  .quote-img img {
    max-width: 120px;
  }
  .quote-btn button {
    width: 70%;
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
  }
  /* .product_box{
    gap: 10px;
  } */
  .product_list {
    width: 48%;
  }
}
@media (max-width: 480px) {
  .health_quote .quote-right .care-hospital {
    width: 50%;
  }
  .health_quote .quote-left .care-hospital {
    width: 50%;
  }
  .quote-right {
    flex-wrap: initial;
    justify-content: space-between;
    width: 100%;
  }
  .health_quote .quote-btn {
    width: 100%;
    align-items: center;
  }
  .health_quote .quote-btn p {
    text-align: center;
  }
  .quote-left {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .my-name h5 b {
    font-size: 15px;
  }
  .my-img {
    width: 40px;
    flex: 0 0 40px;
  }
  .my-img img {
    max-width: 40px;
  }
  .container-btn button {
    height: 30px;
    font-size: 10px;
  }
  .quote-card-1 {
    padding: 5px;
  }
  .quote-img img {
    max-width: 80px;
  }
  .care-amount h6 {
    font-size: 11px;
    line-height: normal;
    margin-bottom: 0;
  }
  .care-amount p {
    margin-bottom: 0;
    font-size: 10px;
  }
  .quote-btn button {
    width: 80%;
    font-size: 11px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
  }
}
@media (max-width: 380px) {
  .idv-choose-inner {
    width: 100%;
    flex-wrap: wrap;
    gap: 15px 0;
  }
  .idv-choose-inner > div {
    width: 100%;
  }
  .secondaryButton {
    width: 100%;
  }
}
@media (max-width: 320px) {
  .my-name h5 b {
    font-size: 15px;
  }
  .my-img {
    width: 40px;
    flex: 0 0 40px;
  }
  .my-img img {
    max-width: 40px;
  }
  .product_text {
    font-size: 10px;
  }
  .quote-btn button {
    width: 80%;
    font-size: 9px;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    background: #56b96c;
    font-weight: bold;
  }
  .quote-img img {
    max-width: 70px;
  }
}

.innerAppliedBox {
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  height: 48px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.innerAppliedBox .innerApplied {
  height: 36px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: 14px;
  margin-right: 10px;
  padding: 0 12px;
  width: auto;
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  border-radius: 40px;
  color: #505f79;
  font-weight: 400;
}
