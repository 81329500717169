.policy-error-container {
  /* padding: 2rem; */
  margin: 0;
  box-sizing: border-box;
}

.policy-error-card {
  /* max-width: 600px; */
  margin: 0 auto;
  /* background: #ffffff; */
  border-radius: 12px;
  /* box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08); */
  padding: 2.5rem;
  text-align: center;
}

.policy-error-icon-wrapper {
  width: 70px;
  height: 70px;
  background: #fee2e2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.policy-error-icon {
  width: 32px;
  height: 32px;
  color: #dc2626;
}

.policy-error-title {
  font-size: 24px;
  color: #1f2937;
  margin: 35px 0 1rem 0;
  font-weight: 600;
  font-family: inherit;
}

.policy-error-message {
  color: #6b7280;
  margin: 0 0 1.5rem 0;
  font-size: 16px;
  line-height: 1.5;
  font-family: inherit;
}

.policy-error-button {
  background: #10b981;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-family: inherit;
}

.policy-error-button:hover {
  background: #059669;
}

.policy-error-button-icon {
  width: 18px;
  height: 18px;
}
.policy-error-card img {
  width: 60%;
}
