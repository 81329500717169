/* new */

.navbar-collapse .navbar-nav .nav-item a {
  font-size: 17px !important;
  font-weight: 500;
  line-height: 39px; /* 195% */
  text-transform: capitalize;
}


.loginBtn {
    background-color: #56b96c;
    color: #fff;
    border: none;
    font-weight: 500;
    border-radius: 0.1875rem;
    padding: 0.7rem 3rem;
  }
