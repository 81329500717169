.skeleton-outer {
  width:100%;
  height: 100%;
  overflow: hidden;
}

.skeleton {
  position: relative;
  width: 100%;
  /* height: 100%; */
  background: #ffffff;
  margin-bottom: 1rem;
  border-radius: 15px;
  overflow: hidden;
}

.skeleton-inner {
  height: 10rem;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
