/* Partneres */
.partners {
  width: 100%;
  /* margin: 0 auto;
  margin-top: 2rem; */
}
.partners h1 {
  font-size: 2rem;
  color: #354c89;
  font-family: Montserrat;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 200% */
  text-transform: capitalize;
}
.partners b {
  color: #358e93;
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
}
.partners-logo-div {
  display: flex;
  /* height: auto; */
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.partners-logo {
  margin: 0.5rem;
  width: 11%;
  /* aspect-ratio: 3/2; */
  min-height:55px;
  max-height: 55px;
  height: 3.5rem;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  box-shadow: 0px 0px 4px 0px #2add52;
}

.partners-logo img {
  padding: 0.8rem;
  overflow: hidden;
  min-height: 70px;
  max-height: 4.5rem;
  height: 4.5rem;
  width: 100%;
  height: 100px;
  display: flex;
  object-fit: contain;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.11);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
/* .partners-logo img:hover {
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.11);
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
} */

.partners-logo .color {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

@media(max-width:991.98px){
  .partners-logo-div{
    gap: 13px;
  }
  .partners-logo{
    width: 15%;
    margin: 0;
  }
}
@media(min-width:575px) and (max-width:767px){
  .partners-logo{
    width: 18%;
    margin: 0;
  }
}
@media(min-width:480px) and (max-width:575px){
  .partners-logo {
    width: 22%;
    margin: 0;
}
}
@media (min-width:320px) and (max-width: 480px) {
  .partners-logo img{
    padding: 10px;
  }
  .partners-logo{
    width: 21%;
  }
}

@media(min-width:280px) and (max-width:320px){
  .partners-logo {
    width: 40%;
    margin: 0;
}
}
